import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, AfterViewInit, Output } from '@angular/core';
import { DataGuardService } from 'app/data-guard.service';

declare const $: any;

@Component({
	selector: 'ui-modal',
	template:
		`<div class="modal" tabindex="-1" (click)="backdropClick($event)" #modal>
			<div class="modal-dialog modal-dialog-centered" [ngClass]="{ 'modal-size-sm': size === 'sm', 'modal-size-lg': size === 'lg', 'modal-size-xl': size === 'xl', 'modal-size-xxl': size === 'xxl', 'modal-dialog-scrollable': scrollable }">
				<div *ngIf="!customContent" class="modal-content border-0 shadow">
					<div *ngIf="header" class="modal-header align-items-center border-0 px-4 py-3">
						<button *ngIf="showBackButton" type="button" class="btn btn-outline-secondary border-0 py-2 px-1 mr-1 ml-n2" (click)="this.back.emit()">
							<i class="wq wq-chevron-left xl"></i>
						</button>
						<h3 class="modal-title flex-weight-1">
							<i *ngIf="icon" class="{{icon}} mr-2 text-secondary"></i>
							{{modalTitle}}
						</h3>
						<ng-content select="[ui-modal-header]"></ng-content>
						<button type="button" class="btn btn-outline-secondary border-0 p-2 mr-n2" (click)="shouldClose()">
							<i class="wq wq-close"></i>
						</button>
					</div>
					<div *ngIf="header" class="hr mx-4"></div>
					<div class="modal-body p-4">
						<ng-content></ng-content>
					</div>
					<div *ngIf="footer" class="modal-footer border-top-0 px-4 pb-4 pt-0">
						<ng-content select="[ui-modal-footer]"></ng-content>
					</div>
				</div>
				<ng-content *ngIf="customContent" select="[ui-modal-custom-content]"></ng-content>
			</div>
		</div>`,
	providers: [DataGuardService]
})
export class UIModalComponent implements OnInit, AfterViewInit {

	@ViewChild('modal') modalElement: ElementRef;

	@Input() modalTitle = '';
	@Input() footer = true;
	@Input() header = true;
	@Input() scrollable = false;
	@Input() size;
	@Input() icon = '';
	@Input() customContent = false;
	@Input() showBackButton = false;
	@Input() closeMessage = { title: 'Are you sure?', message: 'If you close this view now, you will lose all your changes' };

	@Output() back = new EventEmitter<any>();

	onClose = new EventEmitter<any>();

	onShow = new EventEmitter<any>();
	onHide = new EventEmitter<any>();

	isOpen = false;

	/**
	 * Controls whether modal can be closed via the escape key or by clicking on the backdrop.
	 */
	easyClose = true;

	constructor(public dataGuard: DataGuardService) { }

	ngOnInit(): void { }

	ngAfterViewInit() {
		$(this.modalElement.nativeElement).on('show.bs.modal', () => { this.onShow.emit(); });
		$(this.modalElement.nativeElement).on('hidden.bs.modal', () => {
			// This should fix scrollbar issues with multiple modals.
			// Bootstrap removes this class by default when the top-most modal is closed,
			// but it should stay as long as there are any modals open.
			if ($('.modal:visible').length) $(document.body).addClass('modal-open');

			this.onHide.emit();
		});
		$(this.modalElement.nativeElement).on('hidePrevented.bs.modal', () => {
			if (this.easyClose) {
				this.shouldClose();
			}
		});
	}

	open() {
		if (this.isOpen) return;
		this.isOpen = true;
		$(this.modalElement.nativeElement).data('modalComponent', this);
		$(this.modalElement.nativeElement).data('bs.modal', null);
		$(this.modalElement.nativeElement).modal({ keyboard: false, backdrop: 'static' });
		$(this.modalElement.nativeElement).modal('show');
	}

	close(data: any = null, triggerEvent = true) {
		$(this.modalElement.nativeElement).data('modalComponent', null);
		$(this.modalElement.nativeElement).modal('hide');
		this.setDirty(false);
		if (triggerEvent) this.onClose.emit(data);
		this.isOpen = false;

		// Focus topmost modal element. Useful when closing the top modal of a stack of open modals.
		// If we don't do this, no element will have focus, therefore the next modal can't be closed
		// by pressing the escape key.
		$('.modal:visible').last().focus();
	}

	async shouldClose() {
		await this.dataGuard.confirm(this.closeMessage.title, this.closeMessage.message).then((choice) => {
			if (choice) this.close();
		});
	}

	setDirty(isDirty = true) {
		this.dataGuard.dataDirty = isDirty;
	}

	backdropClick(event) {
		if ($(event.target).is('.modal')) {
			event.stopImmediatePropagation();
		}
	}

}
