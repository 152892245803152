import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { Pagination } from 'app/shared/pagination';
import { UIGridBaseComponent } from 'app/ui/ui-grid-base.component';
import { AlertEditModalComponent } from '../alert-edit-modal/alert-edit-modal.component';
import { ModalAlertPopupComponent } from 'app/modal/modal-alert-popup/modal-alert-popup.component';

@Component({
	selector: 'app-alert-list',
	templateUrl: './alert-list.component.html'
})
export class AlertListComponent extends UIGridBaseComponent implements OnInit {

	@ViewChild(AlertEditModalComponent) alertEditModal: AlertEditModalComponent;
	@ViewChild(ModalAlertPopupComponent) alertPopupModal: ModalAlertPopupComponent;

	list;
	search = '';
	pagination = new Pagination();

	constructor(
		public app: AppService,
		private api: ApiService,
		private router: Router
	) {
		super();
	}

	ngOnInit() {
		this.refresh();
	}

	refresh(clear = true) {
		if (clear) this.list = null;
		this.api.alert.list(list => {
			this.list = list;
		});
	}

	dismissAll() {
		this.api.alert.dismissAll(() => {
			this.api.refreshAlerts();
			this.refresh(false);
		});
	}

	openAlert(id) {
		this.api.alert.details(id, data => {
			this.api.alert.dismiss(id, () => {
				this.api.refreshAlerts();
				this.refresh(false);
			});

			switch (data.alert_type) {
				case 'wequote':
					this.alertPopupModal.open([data]);
					break;

				case 'new_snagg_user':
					this.app.dialog.pop('New Snagg User', data.title, ['Close', '*View Snagg Users'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'settings', 'integration']);
						}
					});
					break;

				case 'new_manual_task':
					this.app.dialog.pop('New Manual Task', data.title, ['Close', '*View Work Order'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'work-order', data.work_order_id]);
						}
					});
					break;

				case 'catalogue_approve':
					this.app.dialog.pop('Catalogue Request Approved', data.title, ['Close', '*View Linked Catalogues'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'catalogue', 'linked']);
						}
					});
					break;

				case 'catalogue_reject':
					this.app.dialog.pop('Catalogue Request Rejected', data.title, ['Close', '*View Linked Catalogues'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'catalogue', 'linked']);
						}
					});
					break;

				case 'catalogue_request':
					this.app.dialog.pop('Catalogue Request', data.title, ['Close', '*View Catalogue Requests'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'catalogue', 'subscriptions']);
						}
					});
					break;

				case 'quote_accept':
					this.app.dialog.pop('Quote Accepted', data.title, ['Close', '*View Quote Summary'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'quote', data.quote_id]);
						}
					});
					break;

				case 'quote_comment':
					this.app.dialog.pop('Customer Comment on Quote', data.title, ['Close', '*View Quote Summary'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'quote', data.quote_id]);
						}
					});
					break;
			}
		});
	}

}
