import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIGridBaseComponent } from 'app/ui/ui-grid-base.component';

@Component({
	selector: 'app-core-introduction',
	templateUrl: './core-introduction.component.html'
})
export class CoreIntroductionComponent extends UIGridBaseComponent implements OnChanges {

	@Input() routeId;
	@Input() isPreview = false;
	@Output() closePreviewEvent = new EventEmitter<void>();

	enableDismissButton = false;

	introPage = null;
	constructor(
		public app: AppService,
		private api: ApiService
	) {
		super();
	}

	closePreview() {
		this.closePreviewEvent.emit();
	}

	refresh() {
		this.enableDismissButton = false;
		this.introPage = null;
		if (this.routeId) {
			this.api.wizard.getIntroPageByLocation({
				location_id: this.routeId,
				is_preview: this.isPreview
			}, introPage => {
				if (!introPage.details.dismissed || this.isPreview) {
					this.introPage = introPage.details;
					if (!this.introPage.video_url) this.enableDismissButton = true;
				} else {
					this.app.introductionRouteId = null;
					this.closePreviewEvent.emit();
				}
			}, _error => {
				this.app.introductionRouteId = null;
				this.closePreviewEvent.emit();
			});
		}
	}

	ngOnChanges() {
		this.refresh();
	}

	dismissIntroPage() {
		if (this.enableDismissButton) {
			if (this.isPreview) {
				this.closePreviewEvent.emit();
			} else {
				this.api.wizard.dismissIntroPage(this.introPage.id, _id => {
					this.app.introductionRouteId = null;
				});
			}
		}
	}

	onVideoEnded() {
		this.enableDismissButton = true;
	}

	watchExtendedVideo(extended_video_url) {
		if (this.enableDismissButton) {
			window.open(extended_video_url, '_blank');
		}
	}

}
