import { AfterViewChecked, ChangeDetectorRef, Component, ComponentFactoryResolver, Directive, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ApiService } from 'app/api.service';
import { Pagination } from 'app/shared/pagination';
import { AppService } from 'app/app.service';
import { UITreeData, UITreeNodeAction, UITreeNodeProgress } from 'app/ui/ui-tree.component';
import { ModalEditProductComponent } from '../modal-edit-product/modal-edit-product.component';

declare const Mangler: any;

@Directive({
	selector: '[modalEditProduct]',
})
export class ModalEditProductDirective {
	constructor(public viewContainerRef: ViewContainerRef) { }
}

class CategoryTree implements UITreeData {

	data;
	groups;
	productSelectModal: ModalSelectProductComponent;

	allCategories = { id: 0, description: 'All Category Groups', type: '', children: [] };

	constructor(data, productSelectModal: ModalSelectProductComponent) {
		this.data = data;
		this.productSelectModal = productSelectModal;
		this.refreshData();
	}

	refreshData() {
		// Add categories to category groups
		if (this.data.groups && this.data.groups.length > 0) {
			this.data.groups.forEach(cg => {
				cg.categories = Mangler.find(this.data.categories, { category_group_id: cg.id });
			});

			this.groups = this.data.groups.slice();
			this.groups.unshift(this.allCategories);
		}
	}

	isGroup(node: any): boolean {
		if (!node || node === this.allCategories) return false;
		if (this.groups.indexOf(node) !== -1) return true;
		return false;
	}

	isCategory(node: any): boolean {
		if (!node || node === this.allCategories) return false;
		if (this.groups.indexOf(node) !== -1) return false;
		return true;
	}

	hasChildren(node: any): boolean { return this.isGroup(node) ? !!node.categories.length : false; }
	getChildren(node: any): any[] { return node ? (this.isGroup(node) ? node.categories : []) : this.groups; }
	getParent(node: any) { return node.category_group_id ? Mangler.findOne(this.groups, { id: node.category_group_id }) : null; }

	getActions(_node: any): UITreeNodeAction[] { return []; }

	getDescription(node: any): string { return node.description; }

	getInfo(_node: any): string { return ''; }

	getDescriptionClass(node: any): string { return (this.isCategory(node) ? 'text-normal' : '') + (node === this.allCategories ? ' text-italic' : ''); }

	getInfoClass(_node: any): string { return ''; }

	hasProgress(_node: any): boolean { return false; }
	getProgress(_node: any): UITreeNodeProgress[] { return []; }

	isExpanded(node: any): boolean { return this.isGroup(node) && this.productSelectModal.isCategoryGroupExpanded(node.id); }

	isSelected(node: any): boolean {
		if (node === this.allCategories) return this.productSelectModal.filterCategoryId === null && this.productSelectModal.filterCategoryGroupId === null;
		if (this.isGroup(node)) return this.productSelectModal.filterCategoryGroupId === node.id;
		if (this.isCategory(node)) return this.productSelectModal.filterCategoryId === node.id;
		return false;
	}

	canHover(_node: any): boolean { return true; }
	canSelect(_node: any): boolean { return true; }
	canDrag(_node: any): boolean { return false; }
	canDrop(_node: any, _targetParent: any, _targetPosition: number): boolean { return false; }

	expand(node: any) { if (this.isGroup(node)) this.productSelectModal.setCategoryGroupExpanded(node.id, true); }
	collapse(node: any) { if (this.isGroup(node)) this.productSelectModal.setCategoryGroupExpanded(node.id, false); }

	select(node: any, _clicked: boolean) {
		if (this.isGroup(node)) {
			this.productSelectModal.selectGroup(node);
		} else if (this.isCategory(node)) {
			this.productSelectModal.selectCategory(node);
		} else {
			this.productSelectModal.viewAll();
		}
	}

	action(_node: any, _action: UITreeNodeAction) { }

	drop(_node: any, _targetParent: any, _targetPosition: number) { }

}

@Component({
	selector: 'modal-select-product',
	templateUrl: './modal-select-product.component.html'
})
export class ModalSelectProductComponent extends UIModalBaseComponent implements AfterViewChecked {

	@ViewChild(ModalEditProductDirective, { static: true }) editProductHost: ModalEditProductDirective;

	@Input() addedProducts = [];

	addAccessory = false;
	categoryGroups: any[] = [];
	categories: any[] = [];
	selectedCategory = null;
	addToToolkit = false;
	categoryTree: CategoryTree;
	data;

	modes = [
		{ id: 'my-products', description: 'My Products', icon: 'wq wq-cart-outline', tutorial: null, show: true },
		{ id: 'my-catalogues', description: 'My Catalogues', icon: 'wq wq-catalogue', tutorial: 504, show: false },
		{ id: 'linked-catalogues', description: 'Linked Catalogues', icon: 'wq wq-catalogue', tutorial: 504, show: true }
	];
	mode = 'my-products';

	tabs: any = {
		all_products: { id: 'all_products', title: 'Live Link Products', show: true, icon: 'wq wq-product', color: 'success' },
		bundles: { id: 'bundles', title: 'Configurable Products', show: true, icon: 'wq wq-customer', color: 'info' },
		options: { id: 'options', title: 'Options', show: false, icon: 'wq wq-radio-checked', color: 'danger' }
	};

	tabList = [];
	activeTab = 'all_products';

	list: any[] = null;
	catalogues: any[] = null;
	selectedCatalogue = null;
	search = '';
	pagination = new Pagination();

	filterCategoryId = null;
	filterCategoryGroupId = null;

	multiSelect = false;
	multiSelectList = [];
	forCatalogue = false;
	costPriceField = null;
	sellPriceField = null;

	_collapsed = true;
	collapsedExceptions = [];

	expandedCategoryGroups = [];

	fromQuoteEditor = false;
	quoteId = null;

	multiSelectProduct = false;

	get collapsed() { return this._collapsed; }
	set collapsed(value) {
		this._collapsed = value;
		this.collapsedExceptions = [];
		this.refresh();
	}

	get expand() { return !this.collapsed; }
	set expand(value) { this.collapsed = !value; }

	constructor(
		public app: AppService,
		private api: ApiService,
		private cdr: ChangeDetectorRef,
		private factory: ComponentFactoryResolver
	) {
		super();
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	open(data: any) {
		this.tabList = [];
		this.tabs.options.show = !!(data?.show_options);
		this.tabList.push(this.tabs.all_products);
		this.tabList.push(this.tabs.bundles);
		this.tabList.push(this.tabs.options);

		this.multiSelect = !!(data?.multi_select);
		this.multiSelectList = [];
		this.addToToolkit = false;
		this.forCatalogue = !!(data?.for_catalogue);
		this.costPriceField = data?.cost_price_field || null;
		this.sellPriceField = data?.sell_price_field || null;

		this.categoryGroups = data?.categoryGroupList || [];
		this.categories = data?.categoryList || [];
		this.selectedCategory = data?.category || null;
		this.addAccessory = data?.accessory || false;
		this.fromQuoteEditor = !!data?.quoteEditor;
		this.quoteId = data?.quote_id || null;

		// Add categories to category groups
		this.categoryGroups.forEach(g => g.categories = []);
		const categoryGroupIndex = Mangler.index(this.categoryGroups, 'id');
		this.categories.forEach(c => {
			const cg = categoryGroupIndex[c.category_group_id];
			cg?.categories.push(c);
		});

		this.multiSelectProduct = !!this.app.orgInfo?.multi_select_product;


		if (this.forCatalogue) {
			// If called with for_catalogue mode, you can only browse your own products, no third party ones.
			this.list = null;
			this.mode = 'my-products';
			this.modes = [
				{ id: 'my-products', description: 'My Products', icon: 'wq wq-cart-outline', tutorial: null, show: true },
				{ id: 'my-catalogues', description: 'My Catalogues', icon: 'wq wq-catalogue', tutorial: 504, show: true }
			];
		}

		if (this.mode === 'selected') {
			this.selectMode('my-products');
		} else {
			if (!this.data) this.refresh();
		}

		if (this.mode === 'linked-catalogues' || this.mode === 'my-catalogues') {
			this.app.tutorial?.validateStep(504);
		} else {
			this.app.tutorial?.invalidateStep(504);
		}

		this.modal.open();
	}

	openNewProductModal() {
		// Dynamically load a new instance of ModalEditProductComponent to avoid a circular reference
		const editorFactory = this.factory.resolveComponentFactory(ModalEditProductComponent);
		const viewContainerRef = this.editProductHost.viewContainerRef;
		viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent<ModalEditProductComponent>(editorFactory);

		componentRef.instance.close.subscribe(value => {
			if (value?.id) {
				const options: any = {
					product_id: value.id
				};

				this.api.product.list(options, data => {
					if (data.list.length) this.selectItem(data.list[0]);
					if (this.fromQuoteEditor || this.multiSelect) this.refreshProducts();
				}, error => {
					this.app.notifications.showDanger(error.message);
				});
			}
			componentRef.destroy();
		});
		componentRef.instance.open({});
	}

	catalogueChange() {
		this.data = null;
		this.filterCategoryGroupId = null;
		this.filterCategoryId = null;
		this.refresh();
	}

	refresh() {
		if (this.catalogues) {
			this.refreshProducts();
		} else {
			if (this.mode === 'linked-catalogues') {
				this.api.catalogue.listApprovedCatalogues(list => {
					this.catalogues = list;
					this.refreshProducts();
				});
			} else {
				this.api.catalogue.listMyCatalogues(list => {
					this.catalogues = list;
					const selectedModeIndex = this.modes.findIndex(mode => mode.id === 'my-catalogues');
					if (selectedModeIndex !== -1) {
						if (this.catalogues.length) {
							this.modes[selectedModeIndex].show = true;
						} else {
							this.modes[selectedModeIndex].show = false;
						}
					}
					this.refreshProducts();
				});
			}
		}
	}

	tabChange(tab) {
		if (!tab.disabled) {
			this.activeTab = tab.id;
			this.refreshProducts();
		}
	}

	refreshProducts() {
		const options: any = {
			sold_to_customer: 1,
			mode: this.mode,
			catalogue_id: this.selectedCatalogue?.id
		};

		if (this.addAccessory) options.is_bundle = 0;

		if (this.forCatalogue && this.mode === 'my-products') options.uncatalogued = 1;
		if (this.costPriceField) options.cost_price_field = this.costPriceField;
		if (this.sellPriceField) options.sell_price_field = this.sellPriceField;

		if (this.filterCategoryId) options.category_id = this.filterCategoryId;
		if (!this.filterCategoryId && this.filterCategoryGroupId) options.category_group_id = this.filterCategoryGroupId;

		if (this.activeTab === 'bundles') options.bundle = 1;
		if (this.quoteId) options.quote_id = this.quoteId;

		this.api.product.list(options, data => {
			this.data = data;
			this.categoryTree = new CategoryTree(this.data, this);
		});
	}

	selectItem(item) {
		if (!item) return;

		const closeModal = () => {
			this.app.tutorial?.validateStep(505);
			this.modal.close({
				product: item,
				category: this.selectedCategory
			});
		};

		if (this.fromQuoteEditor) {
			if (this.addToToolkit && this.selectedCategory !== null) {
				this.api.product.addToToolkit({
					category_id: this.selectedCategory,
					product_id: item.id
				}, closeModal, error => {
					this.app.notifications.showDanger(error.message);
				});
			} else {
				if (this.multiSelectProduct) {
					this.modal.onClose.emit({ product: item, category: this.selectedCategory });
				} else {
					closeModal();
				}
			}
		} else if (this.multiSelect) {
			const found = Mangler.findOne(this.multiSelectList, { id: item.id });
			if (found) {
				const i = this.multiSelectList.indexOf(found);
				if (i !== -1) this.multiSelectList.splice(i, 1);
			} else {
				this.multiSelectList.push(item);
			}
			this.multiSelectList = this.multiSelectList.slice();
		} else {
			this.modal.close(item);
		}
	}

	isRecentlyAddedProduct(id) {
		return this.addedProducts.some(item => item.id === id);
	}

	getAddedProducts(id) {
		return this.addedProducts.find(item => item.id === id);
	}

	selectMode(mode) {
		this.selectedCatalogue = null;
		this.mode = mode;

		if (this.mode === 'linked-catalogues' || this.mode === 'my-catalogues') {
			this.app.tutorial?.validateStep(504);
		} else {
			this.app.tutorial?.invalidateStep(504);
		}

		if (mode === 'selected') return;

		this.data = null;

		this.catalogues = null;
		this.filterCategoryGroupId = null;
		this.filterCategoryId = null;
		this.refresh();
	}

	viewAll() {
		this.filterCategoryGroupId = null;
		this.filterCategoryId = null;
		this.refreshProducts();
	}

	selectGroup(item) {
		this.filterCategoryGroupId = item.id;
		this.filterCategoryId = null;
		this.refreshProducts();
	}

	selectCategory(item) {
		this.filterCategoryGroupId = null;
		this.filterCategoryId = item.id;
		this.refreshProducts();
	}

	isCategoryGroupExpanded(id) {
		return !!Mangler.first(this.expandedCategoryGroups, id);
	}

	setCategoryGroupExpanded(id, state) {
		if (state) {
			if (!Mangler.first(this.expandedCategoryGroups, id)) this.expandedCategoryGroups.push(id);
		} else {
			Mangler.filter(this.expandedCategoryGroups, { $ne: id });
		}
		this.expandedCategoryGroups = this.expandedCategoryGroups.slice();
	}

	addProductsButton() {
		if (this.multiSelectList.length) {
			this.modal.close(this.multiSelectList);
		}
	}

	isItemSelected(item) {
		return !!Mangler.findOne(this.multiSelectList, { id: item.id });
	}

}
