import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalEditCustomerContactComponent } from '../modal-edit-customer-contact/modal-edit-customer-contact.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-customer',
	templateUrl: './modal-edit-customer.component.html'
})
export class ModalEditCustomerComponent extends UIModalBaseComponent {

	@ViewChild(ModalEditCustomerContactComponent) editContactModal: ModalEditCustomerContactComponent;
	@ViewChild('multiselectdropdown') multiselectdropdown: ElementRef;

	id;
	data;

	showDeleted = false;
	editedContact = null;

	disabled = false;
	editable = true;
	selectedLabels = [];
	labelList = [];
	isDropdownOpen = false;
	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
		this.editable = !this.app.orgInfo?.disallow_contact_edit;
	}

	open(mdata: any) {
		this.id = mdata.id;
		this.data = null;
		this.showDeleted = false;
		this.editedContact = null;
		this.isDropdownOpen = false;

		if (this.id === 'new') {
			this.disabled = true;
			this.api.customer.new(data => {
				this.disabled = false;
				this.data = data;

				if (mdata.prefill) {
					Mangler.each(mdata.prefill, (k, v) => { this.data.record[k] = v; });
				}

				this.labelList = Mangler.clone(data.label_list);
				// Filter out labels from label_list that are already selected
				this.labelList = this.labelList.filter(label => (!this.selectedLabels.some(selectedLabel => selectedLabel.id === label.id)));
				this.modal.open();
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error.message);
			});
		} else {
			this.disabled = true;
			this.api.customer.details(this.id, data => {
				this.disabled = false;
				this.data = data;
				this.selectedLabels = Mangler.clone(data.record.labels);
				this.labelList = Mangler.clone(data.label_list);
				// Filter out labels from label_list that are already selected
				this.labelList = this.labelList.filter(label => (!this.selectedLabels.some(selectedLabel => selectedLabel.id === label.id)));
				this.modal.open();
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error.message);
			});
		}
	}

	toggleSelection(label: any) {
		const index = this.selectedLabels.findIndex(selectedLabel => selectedLabel.id === label.id);
		if (index !== -1) {
			// Remove the label from selectedLabels and add it back to labelList
			this.selectedLabels.splice(index, 1);
			this.labelList.push(label); // Corrected variable name
		} else {
			// Add the label to selectedLabels and remove it from labelList
			this.selectedLabels.push(label);
			const labelIndex = this.labelList.findIndex(item => item.id === label.id); // Find index of label in label_list
			if (labelIndex !== -1) {
				this.labelList.splice(labelIndex, 1); // Remove label from label_list
			}
		}
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	@HostListener('document:click', ['$event'])
	handleClickOutside(event: Event) {
		if (this.multiselectdropdown && !this.multiselectdropdown.nativeElement.contains(event.target)) {
			this.isDropdownOpen = false;
		}
	}

	saveButton() {
		this.disabled = true;
		this.data.record.labels = this.selectedLabels;
		this.api.customer.save(this.data.record, savedId => {
			this.disabled = false;
			this.app.notifications.showSuccess(this.data.record.id === 'new' ? 'Customer created.' : 'Customer updated.');
			this.modal.close(savedId);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	deleteButton() {
		this.app.dialog.pop('Delete Customer', 'Are you sure you want to delete this customer?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				this.disabled = true;
				this.api.customer.delete(this.data.record.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Customer deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	deleteContact(contact) {
		const contactName = ((contact.contact_first_name || '') + ' ' + (contact.contact_surname || '')).trim();

		this.app.dialog.pop('Delete Contact', 'Are you sure you want to delete contact "' + contactName + '"?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				contact.archived = 1;
				this.checkPrimary();
			}
		});
	}

	setPrimary(contact) {
		if (!contact) return;
		if (!this.editable) return;

		this.data.record.contacts.forEach(c => { c.is_primary = 0; });
		contact.is_primary = 1;
		if (contact.archived) this.checkPrimary();
	}

	checkPrimary() {
		const primary = Mangler.findOne(this.data.record.contacts, { is_primary: 1 });
		if (!primary || primary.archived) {
			// Not set, or set to archived contact
			// Set flag on first contact we find
			const first = Mangler.findOne(this.data.record.contacts, { archived: 0 });
			if (first) this.setPrimary(first);
		}
	}

	editContact(contact) {
		this.editedContact = contact;
		this.editContactModal.open(contact);
	}

	addContact() {
		this.editedContact = null;
		this.editContactModal.open({
			id: 'new',
			contact_first_name: '',
			contact_surname: '',
			contact_position: '',
			contact_email: '',
			contact_mobile: '',
			address_line_1: '',
			address_line_2: '',
			address_line_3: '',
			posttown: '',
			county: '',
			postcode: '',
			country: '',
			is_primary: 0,
			archived: 0,
			delivery_address: []
		});
	}

	copyToContacts() {
		this.editContactModal.open({
			id: 'new',
			contact_first_name: this.data.record.name ?? '',
			contact_surname: '',
			contact_position: '',
			contact_email: this.data.record.email_address ?? '',
			contact_mobile: this.data.record.phone_number ?? '',
			address_line_1: this.data.record.address_line_1 ?? '',
			address_line_2: this.data.record.address_line_2 ?? '',
			address_line_3: this.data.record.address_line_3 ?? '',
			posttown: this.data.record.posttown ?? '',
			county: this.data.record.county ?? '',
			postcode: this.data.record.postcode ?? '',
			country: this.data.record.country ?? '',
			is_primary: 0,
			archived: 0,
			delivery_address: this.data.record.delivery_address ?? []
		});

	}

	editContactModalClosed(data) {
		if (data) {
			if (this.editedContact) {
				Mangler.merge(this.editedContact, data);
			} else {
				this.data.record.contacts.push(data);
			}
			this.checkPrimary();
		}
	}

}
