<form>
	<ui-modal modalTitle="Contact Details">
		<ng-container *ngIf="data">
			<ui-form labelWidth="10rem">
				<ui-form-control label="First Name">
					<input class="form-control" [(ngModel)]="data.contact_first_name" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
				<ui-form-control label="Surname">
					<input class="form-control" [(ngModel)]="data.contact_surname" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Position">
					<input class="form-control" [(ngModel)]="data.contact_position" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Email Address">
					<input class="form-control" [(ngModel)]="data.contact_email" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Mobile Number">
					<input class="form-control" [(ngModel)]="data.contact_mobile" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
				<ui-form-control label="Address">
					<input class="form-control" [(ngModel)]="data.address_line_1" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.address_line_2" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.address_line_3" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Town">
					<input class="form-control" [(ngModel)]="data.posttown" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="County">
					<input class="form-control" [(ngModel)]="data.county" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Postcode">
					<input class="form-control w-50" [(ngModel)]="data.postcode" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Country">
					<input class="form-control" [(ngModel)]="data.country" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<hr>

				<div class="d-flex">
					<h3 class="flex-weight-1">Delivery Address</h3>
				</div>
				<table class="table table-hover" *ngIf="data.delivery_address">
					<tbody>
						<ng-container *ngFor="let delivery of data.delivery_address">
							<tr>
								<td>
									<div *ngIf="delivery.address_line_1">{{delivery.address_line_1}}</div>
									<div *ngIf="delivery.address_line_2">{{delivery.address_line_2}}</div>
									<div *ngIf="delivery.address_line_3">{{delivery.address_line_3}}</div>
									<div *ngIf="delivery.posttown">{{delivery.posttown}}</div>
									<div *ngIf="delivery.county">{{delivery.county}}</div>
									<div *ngIf="delivery.postcode">{{delivery.postcode}}</div>
									<div *ngIf="delivery.country">{{delivery.country}}</div>
								</td>
								<td class="shrink nowrap">
									<button *ngIf="!delivery.archived && delivery.is_primary" type="button" class="btn btn-outline-primary btn-sm border-0 px-1" (click)="setPrimary(delivery)">Primary</button>
									<button *ngIf="!delivery.archived && !delivery.is_primary" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="setPrimary(delivery)">Secondary</button>
								</td>
								<td class="shrink nowrap">
									<button *ngIf="!delivery.archived" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="editDeliveryAddress(delivery)"><i class="wq wq-edit"></i></button>
								</td>
								<td class="shrink nowrap">
									<button *ngIf="delivery.archived" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="delivery.archived = 0"><i class="wq wq-undo"></i></button>
									<button *ngIf="!delivery.archived && editable" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteDeliveryAddress(delivery)"><i class="wq wq-delete"></i></button>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
				<button *ngIf="editable" type="button" class="btn btn-outline-success btn-sm mr-2" (click)="addDeliveryAddress()"><i class="wq wq-add mr-2"></i>Add Delivery Address</button>

			</ui-form>
		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>{{editable ? 'Cancel' : 'Close'}}</button>
		<button *ngIf="editable" type="submit" class="btn btn-primary" (click)="saveButton()" ui-modal-footer>Save</button>
	</ui-modal>
</form>

<modal-edit-contact-delivery-address (close)="editDeliveryAddressModalClosed($event)"></modal-edit-contact-delivery-address>
