<ui-modal modalTitle="Select Customer" size="xl">

	<div *ngIf="customers" class="input-group mb-2">
		<span class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></span>
		<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" appDesktopFocus>
	</div>

	<div style="height: 600px;" class="scrollable mb-2">
		<table *ngIf="customers" class="table table-hover vam">
			<thead>
				<tr>
					<th class="nowrap sticky">Customer Name</th>
					<th class="nowrap sticky">Account No.</th>
					<th class="nowrap sticky">Phone Number</th>
					<th class="nowrap sticky">Email Address</th>
					<th class="nowrap sticky">Primary Contact</th>
					<th class="nowrap sticky">Assigned to</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of customers.list | keywords:search:['name', 'account_no', 'posttown', 'postcode', 'user_name', 'contact_first_name', 'contact_surname', 'contact_email', 'phone_number', 'email_address', 'contact_mobile', 'website_url'] | pagination:pagination" class="selectable" (click)="selectItem(item)">
					<td>
						<b>{{item.name}}</b>
						<img *ngIf="item.zoho_id" src="assets/img/integration/zoho-crm-small.png" class="ml-1" style="height: 1em;" ngbTooltip="Zoho CRM">
						<div class="text-secondary">{{item.posttown}}{{item.posttown && item.postcode ? ',' : ''}} {{item.postcode}}</div>
					</td>
					<td>{{item.account_no}}</td>
					<td>{{item.phone_number}}</td>
					<td><a *ngIf="item.email_address" href="mailto:{{item.email_address}}" (click)="$event.stopPropagation()">{{item.email_address}}</a></td>
					<td>
						<div class="text-bold">{{item.contact_first_name}} {{item.contact_surname}}</div>
						<div *ngIf="item.contact_email"><a href="mailto:{{item.contact_email}}" (click)="$event.stopPropagation()">{{item.contact_email}}</a></div>
						<div *ngIf="item.contact_mobile">{{item.contact_mobile}}</div>
					</td>
					<td>{{item.user_name}}</td>
				</tr>
				<tr *ngIf="!pagination.count">
					<td colspan="6" class="text-secondary">No customers found.</td>
				</tr>
			</tbody>
		</table>
	</div>

	<app-pagination [pagination]="pagination"></app-pagination>

	<button *ngIf="!app.orgInfo?.disallow_contact_edit" class="btn btn-success" (click)="addCustomer()" [disabled]="disabled" ui-modal-footer><i class="wq wq-add mr-2"></i>New Customer</button>
	<div class="flex-weight-1" ui-modal-footer></div>
	<button class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>

</ui-modal>

<modal-edit-customer (close)="customerEditModalClosed($event)"></modal-edit-customer>
